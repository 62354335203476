<template>
    <div class="p-6" style="width: 650px">
        <DialogTitle style="margin-left: -10px; margin-right: -10px" label="Prompt" align="center" />
        <Content class="my-3" label="Prompt ID" text="PRID125" />
        <Content class="my-3" label="Question Type" text="PTE Practice" />
        <Content class="my-3" label="Question Category" text="Reading" />
        <Content class="my-3" label="Prompt" text="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta similique nemo maxime magnam magni architecto blanditiis accusantium consectetur autem animi!" />
        
        <Content class="my-10" label="Status" text="In-active" text-color="text-tahiti-gold text-capitalize" />
        <div class="flex justify-center">
            <md-button class="bg-default text-uppercase rounded" @click="dialog(false)">Back</md-button>
            <md-button class="bg-victoria text-white text-uppercase rounded">Go to edit page</md-button>
        </div>
    </div>
</template>

<script>
import { DialogTitle } from "@/components";
import Content from "@/components/atom/Content";
import { mapMutations } from "vuex";
export default {
  components: {
    DialogTitle,
    Content
  },
  data() {
      return {
          currentIndex: 0,
      }
  },
  methods: mapMutations({
      dialog: "setShowDialog",
    }),
}
</script>