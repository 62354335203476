import MockTest from "./MockTest";
import PracticeTest from "./PracticeTest"
import PTEPractice from "./PTEPractice"
import QuizTest from "./QuizTest"

export default {
    MockTest,
    QuizTest,
    PTEPractice,
    PracticeTest
}