<template>
  <form @submit.prevent="onSubmit" class="p-6">
    <DialogTitle bg-color="bg-victoria" label="Create New Branch" align="center" />
    <div class="md-layout md-gutter mt-6">
      <div class="md-layout-item md-size-75 mb-3">
        <InputFieldComponent
          itali
          label="Name of Branch"
          v-model.trim="$v.form.name.$model"
          :message="!$v.form.name.required && $v.form.name.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-40 mb-3">
        <InputFieldComponent
          itali
          label="Contact Number"
          v-model.trim="$v.form.number.$model"
          :message="!$v.form.number.required && $v.form.number.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-25 mb-3">
        <InputFieldComponent
          itali
          label="Email Address"
          v-model.trim="$v.form.email.$model"
          :message="!$v.form.email.required && $v.form.email.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-100 mb-3">
        <label for="" class="text-uppercase text-base font-semibold mb-1 inline-block text-italic">Address</label>
        <TextAreaComponent
            v-model.trim="$v.form.address.$model"
            :message="!$v.form.address.required && $v.form.address.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-33 mb-3">
        <SelectComponent
          label="Assign Admin"
          italic
          class="w-full"
          v-model.trim="$v.form.adminId.$model"
          :message="!$v.form.adminId.required && $v.form.adminId.$dirty ? 'Field is required' : null"
          />
      </div>
      <div class="md-layout-item md-size-33 mb-3">
        <SelectComponent
          label="Select Course"
          italic
          class="w-full"
          v-model.trim="$v.form.courseId.$model"
          :message="!$v.form.courseId.required && $v.form.courseId.$dirty ? 'Field is required' : null"
          />
      </div>
      <div class="md-layout-item md-size-33 mb-3">
        <SelectComponent
          label="Select Group"
          italic
          class="w-full"
          v-model.trim="$v.form.groupId.$model"
          :message="!$v.form.groupId.required && $v.form.groupId.$dirty ? 'Field is required' : null"
          />
      </div>
      <div class="md-layout-item md-size-33 mb-3">
          <Datepicker
            label="Starting Date"
            v-model.trim="$v.form.startDate.$model"
            :message="!$v.form.startDate.required && $v.form.startDate.$dirty ? 'Field is required' : null"
        />
      </div>

    </div>
    <div class="flex justify-end align-center md-layout-item md-size-100 mb-3">
        <md-button type="submit" class="bg-victoria text-white">SAVE</md-button>
    </div>
  </form>
</template>

<script>
import { 
  SelectComponent,
  InputFieldComponent,
  DialogTitle,
  Datepicker,
  TextAreaComponent,
  } from "@/components";
import { required } from "vuelidate/lib/validators";
import { mapMutations } from "vuex";
export default {
  components: {
    Datepicker,
    DialogTitle,
    TextAreaComponent,
    SelectComponent,
    InputFieldComponent
  },
  data() {
    return {
      radio: true,
      form: {
        name: "",
        number: "",
        email: "",
        address: "",
        adminId: "",
        courseId: "",
        groupId: "",
        startDate: ""
      }
    };
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar"
    }),
    onSubmit() {
      this.$v.$touch();
      if(this.$v.$invalid) {
        return;
      }
      this.setShowSnackbar('Course saved successfully !');
      setTimeout(() => {
       this.dialog(false);
      }, 1000);
    }
  },
  validations: {
    form: {
      name: {required},
      number: {required},
      email: {required},
      address: {required},
      adminId: {required},
      courseId: {required},
      groupId: {required},
      startDate: {required}
    }
  }
};
</script>

<style></style>
