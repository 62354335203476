<template>
    <div class="w-screen p-6">
        <div class="md-layout md-gutter">
           <div class="md-layout bg-white" >
                
                <div class="md-layout-item md-size-100 review-card">
                    <Content textColor="text-victoria" labelClass="text-gray-900 " class="my-3 text-uppercase w-full"  text="Basic Information" /> 
                    <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Name of the Director/Owner"  :text="basicInfo.name" /> 
                    <div class="flex">
                    <div class="md-layout-item md-size-50 -ml-2">
                        <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Email Address"  :text="basicInfo.email" /> 
                    </div>
                    <div class="md-layout-item md-size-50">
                        <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Contact Number"  :text="basicInfo.user_profile?basicInfo.user_profile.contact_number:''" /> 
                    </div>
                    </div>
                </div>

                <div class="md-layout-item md-size-100 review-card mt-2">
                    <Content textColor="text-victoria" labelClass="text-gray-900 " class="my-3 text-uppercase w-full"  text="Address" /> 
                    <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Address"  :text="basicInfo.user_profile?basicInfo.user_profile.address:''" /> 
                    <div class="flex">
                    <div class="md-layout-item md-size-50 -ml-2">
                        <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Country" :text="basicInfo.user_profile && basicInfo.user_profile.country_detail?basicInfo.user_profile.country_detail.name:''" /> 
                    </div>
                    <div class="md-layout-item md-size-50">
                        <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="State"  :text="basicInfo.user_profile && basicInfo.user_profile.postal_code_detail?basicInfo.user_profile.postal_code_detail.state:''"/> 
                    </div>
                    </div>
                    <div class="flex">
                    <div class="md-layout-item md-size-50 -ml-2">
                        <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="City"  :text="basicInfo.user_profile && basicInfo.user_profile.postal_code_detail?basicInfo.user_profile.postal_code_detail.city:''" /> 
                    </div>
                    <div class="md-layout-item md-size-50">
                        <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Postal Code"  :text="basicInfo.user_profile && basicInfo.user_profile.postal_code_detail?basicInfo.user_profile.postal_code_detail.name:''" /> 
                    </div>
                    </div>
                </div>

                <div class="md-layout-item md-size-100 review-card mt-2">
                    <Content textColor="text-victoria" labelClass="text-gray-900 " class="my-3 text-uppercase w-full"  text="Business Info" /> 
                     <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Business Name"  :text="content.business_name" /> 
                   <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="ABN/ACN Number"  :text="content.business_account_number" /> 
                    <div class="flex">
                    <div class="md-layout-item md-size-50 -ml-2">
                        <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Email Address"  :text="content.business_email" /> 
                    </div>
                    <div class="md-layout-item md-size-50">
                        <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Contact Number"   :text="content.business_contact_number" /> 
                    </div>
                    </div>
                    <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Address"  :text="content.business_full_address" /> 
                    <div class="flex">
                    <div class="md-layout-item md-size-50 -ml-2">
                        <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Country" :text="basicInfo.user_profile && basicInfo.user_profile.country_detail?basicInfo.user_profile.country_detail.name:''" /> 
                    </div>
                    <div class="md-layout-item md-size-50">
                        <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="State" :text="basicInfo.user_profile && basicInfo.user_profile.postal_code_detail?basicInfo.user_profile.postal_code_detail.state:''" /> 
                    </div>
                    </div>
                    <div class="flex">
                    <div class="md-layout-item md-size-50 -ml-2">
                        <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="City"  :text="basicInfo.user_profile && basicInfo.user_profile.postal_code_detail?basicInfo.user_profile.postal_code_detail.city:''"  /> 
                    </div>
                    <div class="md-layout-item md-size-50">
                        <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Postal Code"  :text="basicInfo.user_profile && basicInfo.user_profile.postal_code_detail?basicInfo.user_profile.postal_code_detail.name:''" /> 
                    </div>
                    </div>
                </div>

                <div class="md-layout-item md-size-100 review-card mt-2">
                    <div class="featur-list">
                        <ul>
                        <li class="text-victoria text-xl text-uppercase font-bold">Package Information <span class="right-position" > {{content.package_type?content.package_type.replace('_',' '):''}} ({{content.payment_type}})</span></li> 
                        <li class="font-bold"> No of Question <span class="right-position" >{{ packageDetail('no_of_question')}}</span></li> 
                        <li class="font-bold"> No of Pte Practice <span class="right-position" >{{ packageDetail('pte_practice')}}</span></li> 
                        <li  class="font-bold"> No of Practice Test <span class="right-position">{{ packageDetail('practice_test')}}</span></li> 
                        <li class="font-bold"> No of Mock Test <span class="right-position">{{ packageDetail('mock_test')}}</span></li> 
                        <li   class="font-bold">No of Quiz <span class="right-position">{{ packageDetail('quiz')}}</span></li> 
                        <li class="font-bold">Materials <span class="right-position">{{ packageDetail('materials')}}</span></li> 
                        <li  class="font-bold"> Live Class <span class="right-position">{{ packageDetail('live_classes')}}</span></li> 
                        <li  class="font-bold">Webinars <span class="right-position">{{ packageDetail('webinars')}}</span></li> 
                        <li  class="font-bold"> One-One Appointment <span class="right-position">{{ packageDetail('one_to_one_appointment')}}</span></li>
                        </ul>
                    </div>
                </div>

                <div class="md-layout-item md-size-100 review-card mt-2">
                    <Content textColor="text-victoria" labelClass="text-gray-900 " class="my-3 text-uppercase w-full"  text="Portal Access" /> 
                    <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Do you need landing page"  :text="content.want_landing_page?'Yes':'No'" /> 
                    <Content v-if="content.want_landing_page" textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Portal Sub-Domain"  :text="content.landing_page_url" /> 
                </div>
                <div class="md-layout-item md-size-100 review-card mt-2">
                    <Content textColor="text-victoria" labelClass="text-gray-900 " class="my-3 text-uppercase w-full"  text="Portal Admin" /> 
                    <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Name of the admin portal"  :text="portalAdmin.name" /> 
                    <!-- <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Designation"  text="Sub-Amin" />  -->
                    <div class="flex">
                    <div class="md-layout-item md-size-50 -ml-2">
                        <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Email Address"  :text="portalAdmin.email" /> 
                    </div>
                    <div class="md-layout-item md-size-50">
                        <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Contact Number"  :text="portalAdmin.user_profile?portalAdmin.user_profile.contact_number:''" /> 
                    </div>
                    </div>
                    <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Address"  :text="portalAdmin.user_profile?portalAdmin.user_profile.address:''" /> 
                    <div class="flex">
                    <div class="md-layout-item md-size-50 -ml-2">
                        <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Country"  :text="portalAdmin.user_profile && portalAdmin.user_profile.country_detail?portalAdmin.user_profile.country_detail.name:''"  /> 
                    </div>
                    <div class="md-layout-item md-size-50">
                        <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="State"  :text="portalAdmin.user_profile && portalAdmin.user_profile.postal_code_detail?portalAdmin.user_profile.postal_code_detail.state:''" /> 
                    </div>
                    </div>
                    <div class="flex">
                    <div class="md-layout-item md-size-50 -ml-2">
                        <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="City"  :text="portalAdmin.user_profile && portalAdmin.user_profile.postal_code_detail?portalAdmin.user_profile.postal_code_detail.city:''" /> 
                    </div>
                    <div class="md-layout-item md-size-50">
                        <Content textColor="black" labelClass="text-gray-900" class="my-3 text-uppercase w-full" label="Postal Code"  :text="portalAdmin.user_profile && portalAdmin.user_profile.postal_code_detail?portalAdmin.user_profile.postal_code_detail.name:''" /> 
                    </div>
                    </div>
                </div>
                </div>
        </div>
    </div>
</template>

<script>

import Content from "@/components/atom/Content";
import VueClipboard from 'vue-clipboard2';
import Vue from 'vue';

Vue.use(VueClipboard)

export default {
  components: {
    Content,
  },
   props: {
      content: {
          type: Object
      }
  },
  data: function () {
    return {
      active: false,
      component: 'ShowCoachingCenterDetails',
      users: [],
      basicInfo: [],
      portalAdmin: [],
      packageInfo: [],
      packages: []
    }
  },
  methods: {
     packageDetail(key) {
      return  this.packageInfo&&this.packageInfo.package_detail&&this.packageInfo.package_detail[key]&&this.packageInfo.package_detail[key]!="undefined"?this.packageInfo.package_detail[key]:0;
    },
  },
  created(){
     this.packages = this.content.packages
    if(this.packages && this.packages.length > 0){
        this.packageInfo = this.packages[0]; 
    }

     this.users = this.content.users
     if(this.users && this.users.length==1){
         this.basicInfo = this.users[0];
         this.portalAdmin = this.users[0];
     }
     if(this.users && this.users.length > 1){

        for (let i = 0; i < this.users.length; i++) {
            if(this.users[i].type=='COACHING_OWNER') this.basicInfo = this.users[i];
            if(this.users[i].type=='PORTAL_ADMIN') this.portalAdmin = this.users[i];
        }
     }
  }

}
</script>
<style scoped>
</style>