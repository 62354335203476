<template>
    <div class="p-6" style="width: 650px">
        <DialogTitle style="margin-left: -10px; margin-right: -10px" :label="content.branchName" align="center" />
      
        <Content class="my-3" label="Branch ID" :text="content.branchId" />
        
        <Content class="my-3" label="Courses" :text="content.supportAndService" content-width="w-full" />
        <div class="md-layout md-gutter">
            <div class="md-layout-item md-xsmall-size-100">
                <Content class="my-3" label="Status" text="Active" text-color="text-green" />
            </div>
            <div class="md-layout-item md-xsmall-size-100">
                <Content class="my-3" label="Created At" :text="content.createdAt" />
            </div>
        </div>
        <div class="flex justify-center">
            <md-button class="outline-gray-400 text-uppercase rounded" @click="dialog(false)">Back</md-button>
            <md-button class="bg-victoria text-white text-uppercase rounded" >Go to Edit Page</md-button>
        </div>
    </div>
</template>

<script>
import { DialogTitle } from "@/components";
import Content from "@/components/atom/Content";
import { mapMutations } from "vuex";
export default {
  components: {
    DialogTitle,
    Content
  },
  props: {
      content: {
          type: Object
      }
  },
  methods: mapMutations({
      dialog: "setShowDialog",
  })
}
</script>