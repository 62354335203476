<template>
  <form @submit.prevent="onSubmit" class="p-6" style="">
    <DialogTitle
      style="margin-left: -10px; margin-right: -10px"
      label="Edit Prompt"
      align="center"
    />
    <div class="my-3">
      <label class="text-uppercase font-bold">Choose Module</label>
      <RadioButtonComponent
        active-class="font-semibold"
        label-class="text-capitalize"
        :items="[
          { id: 1, label: 'PTE Practice' },
          { id: 2, label: 'Practice Test' },
          { id: 3, label: 'Mock Test' },
          { id: 4, label: 'Quiz Test' },
        ]"
        v-model.trim="$v.form.module.$model"
        :message="
          !$v.form.module.required && $v.form.module.$dirty
            ? 'Field is required'
            : null
        "
      />
    </div>
    <div class="my-3">
      <label class="text-uppercase font-bold">Choose Section</label>
      <RadioButtonComponent
        active-class="font-semibold"
        label-class="text-capitalize"
        :items="[
          { id: 1, label: 'Reading' },
          { id: 2, label: 'Writing' },
          { id: 3, label: 'Speaking' },
          { id: 4, label: 'Listening' },
        ]"
        v-model.trim="$v.form.section.$model"
        :message="
          !$v.form.section.required && $v.form.section.$dirty
            ? 'Field is required'
            : null
        "
      />
    </div>
    <div class="my-3">
      <SelectComponent
        label="Choose Item"
        class="w-74"
        v-model.trim="$v.form.item.$model"
        :message="
          !$v.form.item.required && $v.form.item.$dirty
            ? 'Field is required'
            : null
        "
      />
    </div>
    <div class="my-3">
      <label class="text-uppercase inline-block font-bold mb-3">Prompt</label>
      <TextEditor
        :label="false"
        v-model.trim="$v.form.prompt.$model"
        :message="
          !$v.form.prompt.required && $v.form.prompt.$dirty
            ? 'Field is required'
            : null
        "
      />
    </div>
    <div class="my-3 flex justify-between">
      <RadioButtonComponent
        active-class="font-semibold"
        label-class="text-capitalize"
        :items="[{ id: 1, label: 'Mark as active' }]"
      />
      <div>
        <md-button
          class="bg-default text-uppercase rounded"
          @click="dialog(false)"
          >Cancel</md-button
        >
        <md-button
          type="submit"
          class="bg-victoria text-white text-uppercase rounded"
          >Save</md-button
        >
      </div>
    </div>
  </form>
</template>

<script>
import {
  DialogTitle,
  SelectComponent,
  RadioButtonComponent,
} from "@/components";
import TextEditor from "@/components/atom/form/TextEditor";
import { required } from "vuelidate/lib/validators";
import { mapMutations } from "vuex";
export default {
  components: {
    RadioButtonComponent,
    TextEditor,
    SelectComponent,
    DialogTitle,
  },
  data() {
    return {
      form: {
        item: "",
        prompt: "",
        module: "",
        section: ""
      },
    };
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar",
    }),
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.setShowSnackbar("Prompt saved successfully !");
      setTimeout(() => {
        this.dialog(false);
      }, 1000);
    },
  },
  validations: {
    form: {
      item: { required },
      prompt: { required },
      module: { required },
      section: { required }
    },
  },
};
</script>
