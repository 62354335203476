<template>
  <form @submit.prevent="onSubmit" class="p-6" style="">
    <DialogTitle
      style="margin-left: -10px; margin-right: -10px"
      label="Create New Prompt"
      align="center"
    />
    <div class="my-3">
      <label class="text-uppercase font-bold">Choose Module</label>
      <RadioButtonComponent
        active-class="font-semibold"
        label-class="text-capitalize"
        :items="[
          { id: 'PTE_PRACTICE', label: 'PTE Practice' },
          { id: 'PRACTICE_TEST', label: 'Practice Test' },
          { id: 'MOCK_TEST', label: 'Mock Test' },
          { id: 'QUIZ_TEST', label: 'Quiz Test' },
        ]"
        v-model.trim="form.type"
      />
    </div>
    <div class="my-3">
      <label class="text-uppercase font-bold">Choose Section</label>
      <RadioButtonComponent
        active-class="font-semibold"
        label-class="text-capitalize"
        :items="[
          { id: 'reading', label: 'Reading' },
          { id: 'writing', label: 'Writing' },
          { id: 'speaking', label: 'Speaking' },
          { id: 'listening', label: 'Listening' },
        ]"
        v-model.trim="section"
      />
    </div>
    <div class="my-3">
      <SelectComponent
        label="Choose Item"
        class="w-74"
        :items="items"
        v-model.trim="form.question_type_id"
      />
    </div>
    <div class="my-3">
      <label class="text-uppercase inline-block font-bold mb-3">Prompt</label>
      <TextEditor
        :label="false"
        v-model.trim="form.title"
      />
    </div>
    <div class="my-3 flex justify-between">
      <md-checkbox v-model="form.active">Mark as active</md-checkbox>
      <div>
        <md-button
          class="bg-default text-uppercase rounded"
          @click="dialog(false)"
          >Cancel</md-button
        >
        <md-button
          type="submit"
          class="bg-victoria text-white text-uppercase rounded"
          >Save</md-button
        >
      </div>
    </div>
  </form>
</template>

<script>
import {
  DialogTitle,
  SelectComponent,
  RadioButtonComponent,
} from "@/components";
import TextEditor from "@/components/atom/form/TextEditor";
import { mapMutations, mapGetters, mapActions } from "vuex";
export default {
  components: {
    RadioButtonComponent,
    TextEditor,
    SelectComponent,
    DialogTitle,
  },
  data() {
    return {
      form: {
        type: "",
        question_type_id: "",
        title: "",
        active: true
      },
      section: ""
    };
  },
  computed: {
    ...mapGetters({
      getQuestionTypes: "question/getQuestionTypes"
    }),
    items() {
      return this.getQuestionTypes.map(item => {
        item.label = item.name;
        return item;
      })
    }
  },
  watch: {
    section(value) {
      this.actGetQuestionTypes(`?section=${value}`)
    }
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar",
    }),
    ...mapActions({
      actGetQuestionTypes: "question/actGetQuestionTypes",
      actSavePrompt: "prompt/actSavePrompt",
      actListPrompt: "prompt/actListPrompt"
    }),
    onSubmit() {
      this.actSavePrompt(this.form)
      .then(({ message }) => {
        this.setShowSnackbar(message);
        this.dialog(false);
        this.actListPrompt();
      })
    },
  },
  created() {
    this.actGetQuestionTypes(`?section=reading`)
  }
};
</script>
