<template>
  <div class="p-6" style="width: 650px">
    <DialogTitle label="Branch Support" align="center" />
    <label class="text-uppercase font-bold text-italic">Branch Support</label>
    <SearchBox
      placeholder="Search Branch.."
      class="w-75p p-2 m-1"
      :results="searchResults"
      v-model="keyword"
      result-key="name"
      @on-result="getSearchResult"
    />
    <div>
      <template v-for="(item, index) in items">
        <div :key="index" class="my-3">
          <HorizontalNumberField
            :amount="amount"
            :max-amount="5"
            :label="item.label"
            @increase-amount="increaseAmount" 
            @decrease-amount="decreaseAmount"
          />
        </div>
      </template>
    </div>
    <div class="flex justify-end">
      <md-button
        class="text-uppercase mr-4"
        style="border-bottom: 1px solid #e0e0e0"
        @click="array = []"
        >Clear All</md-button
      >
      <md-button class="bg-victoria text-white text-uppercase rounded"
        >Save</md-button
      >
    </div>
  </div>
</template>

<script>
import { DialogTitle, SearchBox, HorizontalNumberField } from "@/components";
import { mapMutations } from "vuex";
import cms from "@/data/cms";
export default {
  components: {
    HorizontalNumberField,
    DialogTitle,
    SearchBox,
  },
  data() {
    return {
      array: [],
      keyword: "",
      selectedKeyword: "",
      items: cms.buttonFilters,
      total: 5,
      amount: 0,
    };
  },
  computed: {
    searchResults() {
      if (this.keyword !== "" && this.keyword !== this.selectedKeyword) {
        return cms.branches.filter((item) =>
          this.toLower(item.name).includes(this.toLower(this.keyword))
        );
      }
      return [];
    },
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    getSearchResult(value) {
      this.keyword = this.selectedKeyword = value;
    },
    decreaseAmount() {
      if (this.amount > 1) {
        this.amount--;
      }
    },
    increaseAmount() {
      if (this.amount < this.total) {
        this.amount++;
      }
    },
    toLower(text) {
      return text.toString().toLowerCase();
    },
  },
};
</script>