<template>
  <div class="my-3">
      <SelectComponent
        label="Choose Qestion Type"
        class="w-74"
        v-model.trim="$v.form.type.$model"
        :items="questionTypes"
        :message="
          !$v.form.type.required && $v.form.type.$dirty
            ? 'Field is required'
            : null
        "
      />
    </div>
</template>

<script>
import {  SelectComponent } from "@/components"
import { required } from "vuelidate/lib/validators";
import cms from "@/data/cms"
export default {
  components: {
      SelectComponent
  },
  data() {
    return {
      questionTypes : cms.questionTypes,
      form: {
        type: "",
      },
    };
  },
  validations: {
    form: {
        type: {required}
    },
  },
};
</script>