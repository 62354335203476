export const prompts = [];

const section = ['Reading', 'Writing', 'Speaking', 'Listening'];
const branches = ['Dhanmondi', 'Uttara', 'Malibug', 'Jatrabari'];
const statuses = ['Active', 'Pending', 'Canceled', 'Success'];

export const types = [
    { id: 1, label: 'PTE Practice', component: 'PTEPractice' },
    { id: 2, label: 'Practice Test', component: 'PracticeTest' },
    { id: 3, label: 'Mock Test', component: 'MockTest' },
    { id: 4, label: 'Quiz Test', component: 'QuizTest' },
  ];


for (let i = 0; i < 100; i++) {
   prompts.push({
       id: i,
       promptId: 'PRID1235',
       section: section[Math.floor(Math.random() * 4)],
       item: i+'-MCQ- Choose single answer',
       description: 'Lorem ipsum dolor site amet',
       createdAt: '24 Dec, 2018, 12:36',
       status: 'Active'
   })
}


export const timeSets = [];

for (let i = 0; i < 100; i++) {
    timeSets.push({
        id: i,
        promptId: 'PRID1235',
        section: section[Math.floor(Math.random() * 4)],
        item: i+'-MCQ- Choose single answer',
        preTime: '0 Sec',
        ansTime: '120 Sec',
        createdAt: '24 Dec, 2018, 12:36',
        status: 'Active'
    })
 }
 
export const introCommunications = [];

for (let i = 0; i < 100; i++) {
    introCommunications.push({
        id: i,
        introId: 'INID1235',
        ptId: 'PTE1345',
        testName: 'Moonlight',
        noOfSlide: '3',
        group: 'Group A',
        branch: branches[Math.floor(Math.random() * 4)],
        createdAt: '24 Dec, 2018, 12:36',
        status: 'Active'
    })
 }


export const endCommunications = [];

for (let i = 0; i < 100; i++) {
    endCommunications.push({
        id: i,
        endId: 'INID1235',
        ptId: 'PTE1345',
        testName: 'Moonlight',
        course: 'Exp.Listening',
        group: 'Group A, Group B, Group C',
        createdBy: 'Kazi Shahin',
        createdAt: '24 Dec, 2018, 12:36',
        status: statuses[Math.floor(Math.random() * 4)],
    })
 }
 