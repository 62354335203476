<template>
  <div class="my-3">
        <label class="text-uppercase font-bold">Choose Quiz Type</label>
        <RadioButtonComponent
          active-class="font-semibold"
          label-class="text-capitalize"
          :items="[
            { id: 1, label: 'Text + Text' },
            { id: 2, label: 'Audio + Test' },
            { id: 3, label: 'Video + Test' }
          ]"
          v-model.trim="$v.form.section.$model"
          :message="
            !$v.form.section.required && $v.form.section.$dirty
              ? 'Field is required'
              : null
          "
        />
      </div>
</template>

<script>
import { RadioButtonComponent } from "@/components";
import { required } from "vuelidate/lib/validators";
export default {
  components: {
    RadioButtonComponent
  },
  data() {
    return {
      form: {
        section: ""
      },
    };
  },
  validations: {
    form: {
      section: { required }
    },
  },
};
</script>