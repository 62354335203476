<template>
        <div class="w-screen p-6">
            <div class="md-layout md-gutter">
                <div class="md-layout-item md-xsmall-size-100 md-large-size-100">
                    <div class="flex">
                    <h2 class="my-3 ml-6"> {{content.jobTitle}} </h2>
                     <h4 class="absolute right-0 mr-6"> Number of Applicant <span class=" bg-link-water rounded text-center  text-victoria font-bold px-3 py-2">{{content.numberOfApplicant}}</span></h4>
                    </div>
                </div>
            </div>

            <div class="md-layout md-gutter">
                <div class="md-layout-item md-xsmall-size-100 md-large-size-100">
                    <div class="flex">
                        <p class="text-uppercase font-bold my-3 ml-6" style="display: inline-block; white-space: nowrap;margin-top: 10px; margin-right: 5px;"> Job URL </p> 
                        <InputFieldComponent  v-model="jobUrl" class="flex w-35p" 
                        :message="copyMessage"
                        
                        />
                        <button class="bg-white ml-1 border-none" type="button"
                            v-clipboard:copy="jobUrl"
                            v-clipboard:success="onCopy"
                            v-clipboard:error="onError">
                            <svg height="15pt" viewBox="-40 0 512 512" fill="#514b99" width="15pt" xmlns="http://www.w3.org/2000/svg">
                                <path d="m271 512h-191c-44.113281 0-80-35.886719-80-80v-271c0-44.113281 35.886719-80 80-80h191c44.113281 0 80 35.886719 80 80v271c0 44.113281-35.886719 80-80 80zm-191-391c-22.054688 0-40 17.945312-40 40v271c0 22.054688 17.945312 40 40 40h191c22.054688 0 40-17.945312 40-40v-271c0-22.054688-17.945312-40-40-40zm351 261v-302c0-44.113281-35.886719-80-80-80h-222c-11.046875 0-20 8.953125-20 20s8.953125 20 20 20h222c22.054688 0 40 17.945312 40 40v302c0 11.046875 8.953125 20 20 20s20-8.953125 20-20zm0 0"/>
                            </svg>
                        </button>
                        
                         
                        <div class="flex absolute right-0 mr-6">
                        <p class="text-uppercase font-bold" style="margin-top: 2px; margin-right: 3px;"> Share this job </p>
                        <md-icon class="bg-heather rounded-full p-1 text-white text-base mx-1 " @click.native="onShowDetails(item)">visibility</md-icon>
                        <md-icon class="bg-victoria rounded-full p-1 text-white text-base mx-1 " @click.native="onShowEdit">edit</md-icon>
                        <md-icon class="bg-danger rounded-full p-1 text-white text-base mx-1 " @click.native="onShowEdit">delete</md-icon>
                        <md-icon class="bg-tahiti-gold rounded-full p-1 text-white text-base mx-1 " @click.native="onShowEdit">share</md-icon>
                        <md-icon class="bg-success rounded-full p-1 text-white text-base mx-1 " @click.native="onShowApplicantList(item.jobId)">chevron_right</md-icon>
                        </div>
                    </div>
                </div>
            </div>
            <div class="md-layout md-gutter">
                <div class="md-layout-item md-xsmall-size-100 md-large-size-30">
                    <Content contenClass='text-sm' contentWidth="w-full" textColor="black" class="my-3 ml-6 font-bold" label="Job Description" :html="content.description" />
                    
                </div>
                
            </div>
            <div class="md-layout md-gutter">
                <div class="md-layout-item md-xsmall-size-100 md-large-size-30">
                    <Content class="my-3 ml-6 font-bold text-uppercase" label="Job Type" text-color="" :text="content.jobType"/>
                </div>
                 <div class="md-layout-item md-xsmall-size-100 md-large-size-70">
                    <Content class="my-3 ml-6 font-bold text-uppercase" contentClass="text-uppercase" :label="'Salary Range ('+content.salaryType+')'" text-color="" :text="content.salaryRangeMin+'$ -'+content.salaryRangeMax+'$'"/>
                </div>
            </div>
            <div class="md-layout md-gutter">
                <div class="md-layout-item md-xsmall-size-100 md-large-size-30">
                    <Content class="my-3 ml-6 font-bold text-uppercase" contentClass="text-uppercase" label="Workplace" text-color="" :text="content.workplace"/>
                </div>
            </div>
            
            <div class="md-layout md-gutter">
                <div class="md-layout-item md-xsmall-size-100 md-large-size-100">
                    <Content contenClass='text-sm' contentWidth="w-full" textColor="black" class="my-3 ml-6 font-bold" label="Who Should Not Apply" :html="content.restrictions" />
                </div>
            </div>
            <div class="md-layout md-gutter">
                <div class="md-layout-item md-xsmall-size-100 md-large-size-100">
                    <Content contenClass='text-sm' contentWidth="w-full" textColor="black" class="my-3 ml-6 font-bold" label="Deadline of Application" :text="content.deadLine" />
                </div>
            </div>
            <div class="md-layout md-gutter">
               <h2 class="my-3 ml-6 text-uppercase">Questions</h2>
                <div class="md-layout-item md-size-100 mb-3" v-for="(input,k) in questions" :key="k">
                    
                        <Content contenClass='text-sm' contentWidth="w-full" textColor="black" class="my-3 ml-6 font-bold" :label="'Question #'+ parseInt(k+1)" :text="input.title" />
                        <CheckboxComponent v-if="input.question_type=='CHECK_LIST'"
                        class="my-3 ml-6"
                        label-class="font-bold"
                        :label-outline="false"
                        :block="true"
                        :items="getItems(input.job_question_details)"

                        />
                        <TextAreaComponent v-if="input.question_type=='SHORT_ANSWER'"
                            placeholder="Write Question"
                            :rows = 5
                            :cols = 150
                            textAreaClass = "w-auto ml-5"
                        />
                        <TextAreaComponent v-if="input.question_type=='LONG_ANSWER'"
                            placeholder="Write Question"
                            :rows = 10
                            :cols = 150
                            textAreaClass = "w-auto ml-5"
                        />
                        <RadioButtonComponent v-if="input.question_type=='RADIO_BUTTON'"
                        label-class="font-bold"
                        class="my-3 ml-6"
                        align="left"
                        :label-outline="false"
                        :block="true"
                        :items="getItems(input.job_question_details)"
                        />
                </div>
                <!-- <div class="md-layout-item md-xsmall-size-100 md-large-size-100">
                    <h2 class="my-3 ml-6 text-uppercase">Questions</h2>
                    <Content contenClass='text-sm' contentWidth="w-full" textColor="black" class="my-3 ml-6 font-bold" label="Question #1" text="What is the coolest software to design the user interface" />
                    <RadioButtonComponent
                    class="my-3 ml-6"
                    :block="true"
                    align="left"
                    active-class="font-semibold"
                    label-class="text-capitalize"
                    :items="[{id: 1, label: 'Adobe XD'}, {id: 2, label: 'Figma'}]"
                    />

                    <Content contenClass='text-sm' contentWidth="w-full" textColor="black" class="my-3 ml-6 font-bold" label="Question #2" text="What is the coolest software to design the user interface" />
                    <TextAreaComponent
                       class="my-3 ml-6"
                        
                    />

                    <Content contenClass='text-sm' contentWidth="w-full" textColor="black" class="my-3 ml-6 font-bold" label="Question #3" text="What is the coolest software to design the user interface" />
                    <TextAreaComponent
                       class="my-3 ml-6"
                        
                    />

                    <Content contenClass='text-sm' contentWidth="w-full" textColor="black" class="my-3 ml-6 font-bold" label="Question #4" text="What is the coolest software to design the user interface" />
                    <CheckboxComponent
                        class="my-3 ml-6"
                        label-class="font-bold"
                        :label-outline="false"
                        :block="true"
                        :items="[{id: 1, label: 'Adobe XD'}, {id: 2, label: 'Figma'}]"
                        />

                    <Content contenClass='text-sm' contentWidth="w-full" textColor="black" class="my-3 ml-6 font-bold" label="Question #5" text="Rate your Design Skill" />
                    <div class="flex my-3 ml-6">
                        <span  class="mt-3 mr-5" v-text="0"></span>
                        <InputFieldComponent  type="range" class="w-84" min="0" max="10" step="1" v-model="range" />
                        <span class="mt-3 ml-5 " v-text="10"></span>
                        <InputFieldComponent  type="text" v-model="range"  class="w-12 ml-5 " />
                    </div>
                    <Content contenClass='text-sm' contentWidth="w-full" textColor="black" class="my-3 ml-6 font-bold" label="Question #6" text="Upload Your latest work" />
                    <FileInputComponent class="my-3 ml-6 w-84"/>

                </div> -->
            </div>
            
            <div class="flex absolute ">
                <md-button class="my-3 ml-6 bg-danger text-white text-uppercase rounded absolate left-0" @click.native="deleteJob(content.jobId)">X Delete</md-button>
            </div>
            <div class="flex justify-center">
                <md-button class="outline-gray-400 text-uppercase rounded" @click="dialog(false)">Back</md-button>
                <md-button class="bg-victoria text-white text-uppercase rounded" @click="onShowEdit(content)" >Go To Edit Page</md-button>
            </div>
            <Dialog>
            <component :content="content" :is="component"></component>
            </Dialog>
            <ConfirmDialog 
            @confirm="actDeleteJob(jobId)"
            @success="deleteSuccess"
            :active.sync="active"
            />
        </div>
        
</template>

<script>

import { 
  InputFieldComponent,
  RadioButtonComponent,
  TextAreaComponent,
  CheckboxComponent,
  ConfirmDialog,
  Dialog
  } from "@/components";
// import FileInputComponent from "@/components/atom/form/FileInputComponent";
import Content from "@/components/atom/Content";
import { mapMutations, mapActions } from "vuex";
import VueClipboard from 'vue-clipboard2';
import { JobEdit } from "@/components/molecule";
import Vue from 'vue';

Vue.use(VueClipboard)

export default {

  name: 'JobDetailsView',
  components: {
    InputFieldComponent, 
    Content,
    RadioButtonComponent,
    TextAreaComponent,
    CheckboxComponent,
    JobEdit,
    ConfirmDialog,
    Dialog
  },
   props: {
      content: {
          type: Object
      }
  },
  data: function () {
    return {
      active: false,
      jobId : '',
      jobUrl: window.location.origin+'/job-circular/'+this.content.jobId,
      range: 0,
      copyMessage:'',
      questions: '',
      component: '',
    }
  },
  computed: {
      total: function () {
      return this.range * 10
    }
  },
  methods: {
      ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar"
    }),
    ...mapActions({
      actGetJobList: 'job/actGetJobList',
      actDeleteJob: "job/actDeleteJob",
      actGetJobQuestions: "job/actGetQuestionsByJobId",
    }),
     getItems(data){

        return data.map((item) =>{
          return { id: item.key, label: item.value }
        });
    },
    onShowEdit(item) {
        
      this.content = item;
      this.component = 'JobEdit';
      console.log(this.component);
      this.dialog(true);
    },
    deleteSuccess(){
        this.dialog(false);
        this.setShowSnackbar('Job deleted successfully !');
    },
    
    deleteJob(jobId) {
      this.active = true;
      this.jobId = jobId;
    },
     onCopy: function (e) {
         if(!this.copyMessage)
         this.copyMessage='You just copied: ' + e.text;
         else
         this.copyMessage = '';
    },
    onError: function (e) {
      
      if(!this.copyMessage)
         this.copyMessage='Failed to copy texts';
     else
        this.copyMessage = '';
        console.log(e);
    }
  },
  created(){
      
      this.actGetJobQuestions(this.content.jobId)
      .then( ( {items} ) => {
        this.questions = items;
      });
  }

}
</script>
<style scoped>
</style>