var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-3"},[_c('label',{staticClass:"text-uppercase font-bold"},[_vm._v("Choose Quiz Type")]),_c('RadioButtonComponent',{attrs:{"active-class":"font-semibold","label-class":"text-capitalize","items":[
          { id: 1, label: 'Text + Text' },
          { id: 2, label: 'Audio + Test' },
          { id: 3, label: 'Video + Test' }
        ],"message":!_vm.$v.form.section.required && _vm.$v.form.section.$dirty
            ? 'Field is required'
            : null},model:{value:(_vm.$v.form.section.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.section, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.section.$model"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }