<template>
  <form @submit.prevent="onSubmit" class="p-6" style="">
    <DialogTitle
      style="margin-left: -10px; margin-right: -10px"
      label="Create End Communication"
      align="center"
    />
    <div class="my-3">
      <label class="text-uppercase font-bold">Choose Type</label>
      <RadioButtonComponent
        active-class="font-semibold"
        label-class="text-capitalize"
        :items="[
          { id: 1, label: 'Practice Test' },
          { id: 2, label: 'Mock Test' },
          { id: 3, label: 'Quiz Test' },
        ]"
        v-model.trim="$v.form.module.$model"
        :message="
          !$v.form.module.required && $v.form.module.$dirty
            ? 'Field is required'
            : null
        "
      />
    </div>
    <div class="md-layout my-3">
      <div class="md-layout-item md-size-40">
        <SelectComponent
          label="Choose Practice Test"
          class="w-full"
          :items="[
            { id: 1, name: 'Reading' },
            { id: 2, name: 'Writing' },
            { id: 3, name: 'Speaking' },
            { id: 4, name: 'Listening' },
          ]"
          v-model.trim="$v.form.item.$model"
          :message="
            !$v.form.item.required && $v.form.item.$dirty
              ? 'Field is required'
              : null
          "
        />
      </div>
      <div class="md-layout-item md-size-50">
          <SearchComponent placeholder="Search" class="p-2 mt-6" />
      </div>
    </div>
    <div class="md-layout  my-3">
      <div class="md-layout-item md-size-40">
        <SelectComponent
          label="Choose Group"
          class="w-full"
          :items="[
            { id: 1, name: 'Reading' },
            { id: 2, name: 'Writing' },
            { id: 3, name: 'Speaking' },
            { id: 4, name: 'Listening' },
          ]"
          v-model.trim="$v.form.item.$model"
          :message="
            !$v.form.item.required && $v.form.item.$dirty
              ? 'Field is required'
              : null
          "
        />
      </div>
    </div>
    <div class="my-3">
      <label class="text-uppercase font-bold">Number of Slide</label>
      <RadioButtonComponent
        active-class="font-semibold"
        label-class="text-capitalize"
        :items="[
          { id: 1, label: '1' },
          { id: 2, label: '2' },
          { id: 3, label: '3' },
        ]"
        v-model.trim="$v.form.module.$model"
        :message="
          !$v.form.module.required && $v.form.module.$dirty
            ? 'Field is required'
            : null
        "
      />
    </div>
    <div class="md-layout md-gutter my-3">
      <div class="md-layout-item md-size-100">
        <label class="text-uppercase inline-block font-bold mb-3"
          >End Communication</label
        >
        <TextEditor
          :label="false"
          v-model.trim="$v.form.prompt.$model"
          :message="
            !$v.form.prompt.required && $v.form.prompt.$dirty
              ? 'Field is required'
              : null
          "
        />
      </div>
      <div class="md-layout-item md-size-100 my-6">
        <InputFieldComponent
            italic
            label="Test Link You Want To Add On Last"
            placeholder="Enter test link"
            v-model.trim="$v.form.duration.$model"
            :message="!$v.form.duration.required && $v.form.duration.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-100">
        <label class="text-uppercase inline-block font-bold mb-3">Message</label>
        <TextEditor
          :label="false"
          v-model.trim="$v.form.prompt.$model"
          :message="
            !$v.form.prompt.required && $v.form.prompt.$dirty
              ? 'Field is required'
              : null
          "
        />
      </div>
    </div>
    <div class="my-3 flex justify-between">
      <RadioButtonComponent
        active-class="font-semibold"
        label-class="text-capitalize"
        :items="[{ id: 1, label: 'Mark as active' }]"
      />
      <div>
        <md-button
          class="bg-default text-uppercase rounded"
          @click="dialog(false)"
          >Cancel</md-button
        >
        <md-button
          type="submit"
          class="bg-victoria text-white text-uppercase rounded"
          >Save</md-button
        >
      </div>
    </div>
  </form>
</template>

<script>
import {
  DialogTitle,
  SelectComponent,
  InputFieldComponent,
  RadioButtonComponent,
} from "@/components";
import TextEditor from "@/components/atom/form/TextEditor";
import SearchComponent from "@/components/atom/SearchBox";
import { required } from "vuelidate/lib/validators";
import { mapMutations } from "vuex";
export default {
  components: {
    RadioButtonComponent,
    InputFieldComponent,
    SearchComponent,
    TextEditor,
    SelectComponent,
    DialogTitle,
  },
  data() {
    return {
      form: {
        item: "",
        prompt: "",
        module: "",
        section: "",
        duration: ""
      },
    };
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar",
    }),
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.setShowSnackbar("Prompt saved successfully !");
      setTimeout(() => {
        this.dialog(false);
      }, 1000);
    },
  },
  validations: {
    form: {
      item: { required },
      prompt: { required },
      module: { required },
      section: { required },
      duration: { required },
    },
  },
};
</script>
