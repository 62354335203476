<template>
  <div class="p-6">
    <DialogTitle label="Bulk Question Upload" align="center" />
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-layout md-gutter">
        <div class="md-layout-item my-3">
          <SelectComponent
            label="Question Section"
            :items="sections"
            return-type="object"
            @getObject="onChangeSection"
            class="w-full"
          />
        </div>

        <div class="md-layout-item my-3">
          <SelectComponent
            :items="questionTypes"
            label="Question Item"
            class="w-full"
            v-model.trim="$v.form.questionType.$model"
            :message="
              !$v.form.questionType.required && $v.form.questionType.$dirty
                ? 'Field is required'
                : null
            "
          />
        </div>
        <div class="md-layout-item md-size-100 mt-3">
          <FileInputCompoment
            label="Import file"
            v-model.trim="$v.form.file.$model"
            :message="
              !$v.form.file.required && $v.form.file.$dirty
                ? 'Field is required'
                : null
            "
          />
        </div>
        <div class="md-layout-item md-size-100">
          <TextAreaComponent
            label="Notes"
            label-class="text-italic"
            v-model.trim="$v.form.notes.$model"
            :message="
              !$v.form.notes.required && $v.form.notes.$dirty
                ? 'Field is required'
                : null
            "
          />
        </div>
      </div>

      <div class="md-layout-item my-3">
        <label class="pr-4 text-center block mb-1 text-uppercase font-bold">
          Preview
        </label>
        <div
          class="rounded outline-gray-400 p-2"
          style="min-height: 250px"
        ></div>
        <div>
          <div class="flex justify-between p-1 mt-4">
            <md-icon @click.native="currentIndex--" class="bg-victoria p-3 text-white m-0 rounded text-2xl pointer"
              >keyboard_arrow_left</md-icon
            >
            <span class="mx-6 text-lg font-semibold">Question {{ currentIndex + 1 }}</span>
            <md-icon @click.native="currentIndex++" class="bg-victoria p-3 text-white m-0 rounded text-2xl pointer"
              >keyboard_arrow_right</md-icon
            >
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-end">
        <md-button>Save as draft</md-button>
        <md-button class="outline-gray-400 rounded">Schedule</md-button>
        <md-button class="bg-victoria text-white rounded" @click="onSubmit">Create</md-button>
    </div>
  </div>
</template>

<script>
import {
  DialogTitle,
  SelectComponent,
  TextAreaComponent,
} from "@/components";
import FileInputCompoment from "@/components/atom/form/FileInputComponent";
import { required } from "vuelidate/lib/validators";
import cms from "@/data/cms"
import { mapMutations } from "vuex";

export default {
  components: {
    SelectComponent,
    TextAreaComponent,
    FileInputCompoment,
    DialogTitle,
  },
  data() {
    return {
      currentIndex: 0,
      sections: cms.sections,
      questionTypes: [],
      form: {
        file: "",
        notes: "",
        questionType: ""
      },
    };
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar",
    }),
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.setShowSnackbar("Bulk upload successfully !");
      setTimeout(() => {
        this.dialog(false);
      }, 1000);
    },
    onChangeSection(item) {
        let type = item.name.toLowerCase();
        this.questionTypes = cms.questionTypes.filter(item => item.group == type)
    }
  },
  validations: {
    form: {
      file: { required },
      notes: { required },
      questionType: { required }
    },
  },
};
</script>