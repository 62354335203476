<template>
    <div class="p-6" style="width: 650px">
        <DialogTitle style="margin-left: -10px; margin-right: -10px" label="Intro class" align="center" />
        <Content class="my-3" label="Class Type" text="Online Class" />
        <div class="md-layout md-gutter">
            <div class="md-layout-item md-xsmall-size-100 md-large-size-30">
             <Content class="my-3" label="Class Date" :text="content.date" />
            </div>
            <div class="md-layout-item md-xsmall-size-100 md-large-size-70">
            <Content class="my-3" label="Class Time" :text="content.time" />
            </div>
        </div>
        <div class="md-layout md-gutter">
            <div class="md-layout-item md-xsmall-size-100 md-large-size-30">
             <Content class="my-3" label="Branch" :text="content.branch"  textColor="text-capitalize" />
            </div>
            <div class="md-layout-item md-xsmall-size-100 md-large-size-70">
            <Content class="my-3" label="Course" :text="content.courseName"  textColor="text-capitalize" />
            </div>
        </div>
        <div class="md-layout md-gutter">
            <div class="md-layout-item md-xsmall-size-100 md-large-size-30">
             <Content class="my-3" label="Medium" :text="content.medium"  textColor="text-capitalize" />
            </div>
            <div class="md-layout-item md-xsmall-size-100 md-large-size-70">
            <Content class="my-3" label="Medium Link" :text="content.zoomLink"  textColor="text-capitalize" />
            </div>
        </div>
        <Content class="my-3" label="Group" :text="content.groupName" />
        <div class="md-layout md-gutter">
            <div class="md-layout-item md-xsmall-size-100 md-large-size-30">
             <Content class="my-3" label="Status" text="Upcoming"  textColor="text-capitalize text-tahiti-gold" />
            </div>
            <div class="md-layout-item md-xsmall-size-100 md-large-size-70">
            <Content class="my-3" label="Created By" :text="content.createdBy"  textColor="text-capitalize" />
            </div>
        </div>
        <div class="flex justify-center">
            <md-button class="outline-gray-400 text-uppercase rounded" @click="dialog(false)">Back</md-button>
        </div> 
    </div>
</template>

<script>
import { DialogTitle } from "@/components";
import Content from "@/components/atom/Content";
import { mapMutations } from "vuex";
export default {
  components: {
    DialogTitle,
    Content
  },
  props: {
      content: {
          type: Object
      }
  },
  methods: mapMutations({
      dialog: "setShowDialog",
  })
}
</script>