<template>
  <form @submit.prevent="onSubmit" class="p-6" style="">
    <DialogTitle
      style="margin-left: -10px; margin-right: -10px"
      label="SET TIME"
      align="center"
    />
    <div class="my-3">
      <label class="text-uppercase font-bold">Choose Type</label>
      <RadioButtonComponent
        return-type="object"
        @getObject="onGetObject"
        active-class="font-semibold"
        label-class="text-capitalize"
        :items="types"
        v-model.trim="$v.form.type.$model"
        :message="
          !$v.form.type.required && $v.form.type.$dirty
            ? 'Field is required'
            : null
        "
      />
    </div>
    <div class="my-3">
    <label class="text-uppercase font-bold">Time Set Option</label>
    <RadioButtonComponent
      active-class="font-semibold"
      label-class="text-capitalize"
      :items="[
        { id: 1, label: 'Question Wise' },
        { id: 2, label: 'Section Wise' },
      ]"
      v-model.trim="$v.form.option.$model"
      :message="
        !$v.form.option.required && $v.form.option.$dirty
          ? 'Field is required'
          : null
      "
    />
  </div>
    <components v-if="form.option == 1" :is="component" ref="child"></components>
    
    <div v-if="form.option == 1" class="md-layout md-gutter">
        <div class="md-layout-item">
            <label class="text-uppercase inline-block font-bold mb-2">Set preparation time</label>
            <div class="flex">
                <InputFieldComponent
                    class="w-16 mr-2"
                    placeholder="mm"
                    v-model.trim="form.preparationTimeMinute"  
                />
                <InputFieldComponent
                    class="w-16 ml-2"
                    placeholder="ss"
                    v-model.trim="form.preparationTimeSecond"
                />

            </div>
        </div>
        <div class="md-layout-item">
            <label class="text-uppercase inline-block font-bold mb-2">Set Answer time</label>
            <div class="flex">
                <InputFieldComponent
                    class="w-16 mr-2"
                    placeholder="mm"
                    v-model.trim="form.answerTimeMinute"
                />
                <InputFieldComponent
                    class="w-16 ml-2"
                    placeholder="ss"
                    v-model.trim="form.answerTimeSecond"
                />

            </div>
        </div>
        <div class="md-layout-item">
            <label class="text-uppercase inline-block font-bold mb-2">Enable Beep After Pre Time</label>
            <RadioButtonComponent
                return-type="object"
                @getObject="onGetObject"
                active-class="font-semibold"
                label-class="text-capitalize"
                :items="[
                    {id: 1, label: 'NO'},
                    {id: 2, label: 'YES'}
                ]"
                v-model.trim="form.enableBeep"
            />
        </div>
    </div>
    <div v-else class="md-layout md-gutter">
        <div class="md-layout-item md-size-30">
            <label class="text-uppercase inline-block font-bold mb-2">Number of Qusetion</label>
                <InputFieldComponent
                    class="w-20"
                    placeholder="00"
                    v-model.trim="form.numberOfQuestion"
                />
        </div>
        
        <div class="md-layout-item ">
            <label class="text-uppercase inline-block font-bold mb-2">Total Time</label>
                <div class="flex">
                    <InputFieldComponent
                    class="w-16 mr-2"
                    placeholder="mm"
                    v-model.trim="form.totalTimeMinute"
                />
                <InputFieldComponent
                    class="w-16 ml-2"
                    placeholder="ss"
                    v-model.trim="form.totalTimeSecond"
                />
                </div>
        </div>

    </div>
    <div class="my-3">
      <label class="text-uppercase inline-block font-bold mb-3">Note <small>( Only for me )</small></label>
      <TextAreaComponent
        v-model.trim="$v.form.note.$model"
        :message="
          !$v.form.note.required && $v.form.note.$dirty
            ? 'Field is required'
            : null
        "
      />
    </div>
    <div class="my-3 flex justify-end">
        <md-button
          class="bg-default text-uppercase rounded"
          @click="dialog(false)"
          >Cancel</md-button
        >
        <md-button
          type="submit"
          class="bg-victoria text-white text-uppercase rounded"
          >Save</md-button
        >
      </div>
  </form>
</template>

<script>
import {
  DialogTitle,
  SelectComponent,
  TextAreaComponent,
  InputFieldComponent,
  RadioButtonComponent,
} from "@/components";
import dynamicComponents from "@/components/molecule/question-and-test-management/set-time/import";
import { types } from "@/data/master/qatm/toolkit";
import { required } from "vuelidate/lib/validators";
import { mapMutations } from "vuex";
export default {
  components: {
    RadioButtonComponent,
    InputFieldComponent,
    TextAreaComponent,
    SelectComponent,
    DialogTitle,
    ...dynamicComponents
  },
  data() {
    return {
      component: 'PTEPractice',
      types,
      form: {
        type: "",
        option: "",
        preparationTime: "",
        preparationTimeMinute: "",
        preparationTimeSecond: "",
        answerTimeMinute: "",
        answerTimeSecond: "",
        enableBeep: "",
        note: ""
      },
    };
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar",
    }),
    onSubmit() {
      this.$v.$touch();
      if(this.form.option == 1) {
        this.$refs.child.$v.$touch();
        if(this.$v.$invalid || this.$refs.child.$v.$invalid) {
            return;
        }
        this.form = {...this.form, ...this.$refs.child.form}
      } else {
        if(this.$v.$invalid) {
            return;
        }
        this.form = {...this.form}
      }
     
      
      this.setShowSnackbar('SET Time saved successfully !');
      setTimeout(() => {
       this.dialog(false);
      }, 1000);
    },
    onGetObject({component}) {
        this.component = component;
    }
  },
  validations: {
    form: {
      type: { required },
      option: { required },
      note: { required },
    }
  }
};
</script>
