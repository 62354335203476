<template>
  <form @submit.prevent="onSubmit" class="p-6">
    <DialogTitle bg-color="bg-victoria" label="Create New Job" align="center" />
    <div class="md-layout md-gutter mt-6">
      <div class="md-layout-item md-size-75 mb-3">
        <InputFieldComponent
            italic
            label="Title"
            v-model.trim="$v.form.title.$model"
            :message="!$v.form.title.required && $v.form.title.$dirty ? 'Field is required' : null"
          />
      </div>
      <div class="md-layout-item md-size-100 mb-3">
        <label for="" class="text-uppercase text-base font-semibold mb-1 inline-block text-italic">Job Description</label>
  
        <TextEditor
          :label="false"
          v-model.trim="$v.form.description.$model"
          :message="!$v.form.description.required && $v.form.description.$dirty ? 'Field is required' : null"
        />
        
      </div>
      <div class="md-layout-item md-size-50 mb-3">
        <RadioButtonComponent
         label="Job Type"
         label-class="font-bold"
         :label-outline="false"
         :block="false"
         :items="jobType"
         v-model.trim="$v.form.job_circular_details.type.$model"
         :message="!$v.form.job_circular_details.type.required && $v.form.job_circular_details.type.$dirty ? 'Field is required' : null"
       
        />
      </div>
      <div class="md-layout-item md-size-50 mb-3">
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-100 pl-0">
            <RadioButtonComponent
            label="Salary range (USD)"
            label-class="font-bold"
            :label-outline="false"
            :block="false"
            :items="salaryType"
            v-model.trim="$v.form.job_circular_details.salary_type.$model"
            :message="!$v.form.job_circular_details.salary_type.required && $v.form.job_circular_details.salary_type.$dirty ? 'Field is required' : null"
            />
            </div>
            <div class="md-layout-item md-size-25 pl-0">
              <InputFieldComponent placeholder="Min" 
              v-model.trim="$v.form.job_circular_details.salary_range_min.$model"
              :message="!$v.form.job_circular_details.salary_range_min.required && $v.form.job_circular_details.salary_range_min.$dirty ? 'Field is required' : null"
              />
            </div>
            <div class="md-layout-item md-size-25  pl-0">
              <InputFieldComponent placeholder="Max"
              v-model.trim="$v.form.job_circular_details.salary_range_max.$model"
              :message="!$v.form.job_circular_details.salary_range_max.required && $v.form.job_circular_details.salary_range_max.$dirty ? 'Field is required' : null"
               />
            </div>
          </div>
      </div>
      <div class="md-layout-item md-size-50 mb-3">
        <RadioButtonComponent
         label="Workplace"
         label-class="font-bold"
         :label-outline="false"
         :block="false"
         :items="workplace"
          v-model.trim="$v.form.job_circular_details.workplace.$model"
          :message="!$v.form.job_circular_details.workplace.required && $v.form.job_circular_details.workplace.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-100 mb-3">
        <label for="" class="text-uppercase text-base font-semibold mb-1 inline-block text-italic">Who Should not apply</label>
         <TextEditor
          :label="false"
          v-model.trim="$v.form.job_circular_details.restrictions.$model"
          :message="!$v.form.job_circular_details.restrictions.required && $v.form.job_circular_details.restrictions.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-50 mb-3 mb-4">
        <label for="" class="text-uppercase text-base font-semibold mb-1 inline-block text-italic">Deadline of Applicantion</label>
        <div class="flex">
          <div class="relative">
            <Datepicker 
              placeholder="Select Date"
              :format="customFormatter"
              :clearButton="true"
              input-class="w-56 m-1 py-3 pl-3 rounded outline-gray-400"
              v-model.trim="$v.form.job_circular_details.deadline.$model"
              :message="!$v.form.job_circular_details.deadline.required && $v.form.job_circular_details.deadline.$dirty ? 'Field is required' : null"
              />
              <svg class="w-5 date-picker-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
                <path d="M5 0L5 4L7 4L7 0 Z M 17 0L17 4L19 4L19 0 Z M 1 3C0.449219 3 0 3.449219 0 4L0 7C0 7.550781 0.449219 8 1 8L1 24L23 24L23 8C23.550781 8 24 7.550781 24 7L24 4C24 3.449219 23.550781 3 23 3L20 3L20 5L16 5L16 3L8 3L8 5L4 5L4 3 Z M 3 8L21 8L21 22L3 22 Z M 5 10L5 12L7 12L7 10 Z M 9 10L9 12L11 12L11 10 Z M 13 10L13 12L15 12L15 10 Z M 17 10L17 12L19 12L19 10 Z M 5 14L5 16L7 16L7 14 Z M 9 14L9 16L11 16L11 14 Z M 13 14L13 16L15 16L15 14 Z M 17 14L17 16L19 16L19 14 Z M 5 18L5 20L7 20L7 18 Z M 9 18L9 20L11 20L11 18 Z M 13 18L13 20L15 20L15 18 Z M 17 18L17 20L19 20L19 18Z" fill="#5B5B5B" />
              </svg>
          </div> 
        </div>
      </div>

      <div class="md-layout-item md-size-100 mb-3  text-lg font-bold text-uppercase pb-2 pt-3 ">Add Questions</div>
      
    <!-- End Question - 01 -->
    <div class="md-layout-item md-size-100 mb-3" v-for="(v, index) in $v.questions.$each.$iter" v-bind:key="index"  >
    <!-- <div class="md-layout-item md-size-100 mb-3" v-for="(input,k) in inputs" :key="k"> -->
      <label  class="text-uppercase text-base font-semibold mb-1 inline-block text-italic">Question {{parseInt(index)+1}}#</label>
        
        <div class="w-full -ml-2">
          <div class="flex">
            <div class="md-layout-item md-size-50 mb-3">
              <SelectComponent
                
                italic
                class="w-full"
                :items="questionType"
                :placeholder="true"
                @input="getQuestionType($event,index)"
             
                v-model.trim="v.question_type.$model"
              
                />
            </div>
            
            <div class="md-layout-item md-size-50 mb-3">
              <CheckboxComponent
                  label-class="font-bold"
                  :label-outline="false"
                  :block="true"
                  :items="[{id: true, label: 'Mark as Compulsory', active:''}]"
                  @input="getIsMandatory($event,index)"
                  
                  />
            </div>
          </div>
            
            <CheckListQuestion @input="onClickChild($event, index)" v-if="showCheckList[index]" />
            <!-- {{showCheckList}}
            {{showShortQuestion}}
            {{showLongQuestion}} -->
            <div v-if="showShortQuestion[index]" class="md-layout-item md-size-100 mb-3">
              <TextAreaComponent
                    placeholder="Write Question"
                    :rows = 5
                    v-model.trim="v.title.$model"
                />
            </div>
            <div v-if="showLongQuestion[index]"  class="md-layout-item md-size-100 mb-3">
              <TextAreaComponent
                    placeholder="Write Question"
                    :rows = 10
                    v-model.trim="v.title.$model"
                />
            </div>
            <div class="md-layout-item md-size-30 mt-12">
              <span @click="questions.pop()" style="position: absolute; right: 0; margin-right: 20px;" class="bg-danger rounded-full pr-2 pl-2 h-5 text-center text-white">X
              </span>
            </div>
        </div> 
        
      </div>
      <div class="flex justify-left align-left md-layout-item md-size-100 mb-3">
        <!--   -->
          <md-button 
          @click="questions.push({title: '', is_mandatory: false, question_type: ''})"
          class="border border-solid font-bold text-victoria">+ Add More Question</md-button>
        </div>
    </div>
    <div class="flex justify-end align-center md-layout-item md-size-100 mb-3">
        <md-button class="text-gray-500" @click="clearAll">Clear All</md-button>
        <md-button type="submit" class="bg-victoria text-white">SAVE</md-button>
    </div>
  </form>
</template>

<script>
import { 
  InputFieldComponent,
  DialogTitle,
  RadioButtonComponent,
  SelectComponent,
  TextAreaComponent,
  CheckboxComponent
  } from "@/components";

import CheckListQuestion from "@/components/molecule/question/items/CheckListQuestion";
import { required } from "vuelidate/lib/validators";
import { mapMutations, mapActions } from "vuex";
import cms from "@/data/cms";
import TextEditor from "@/components/atom/form/TextEditor";
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';
export default {
  name: 'JobCreate',
  components: {
    Datepicker,
    DialogTitle,
    InputFieldComponent,
    RadioButtonComponent,
    TextEditor,
    SelectComponent,
    CheckListQuestion,
    TextAreaComponent,
    CheckboxComponent
  },
  data() {
    return {
      showCheckList: Object.assign({},new Array(100).join(0).split('').map(function(e) {return parseInt(e, 10);})),
      showShortQuestion: Object.assign({},new Array(100).join(0).split('').map(function(e) {return parseInt(e, 10);})),
      showLongQuestion: Object.assign({},new Array(100).join(0).split('').map(function(e) {return parseInt(e, 10);})),
      radio: true,
      jobType: cms.jobTypeCheckBox,
      salaryType: cms.salaryType,
      workplace: cms.workplace,
      questionType: cms.questionType,
      questions: [
        {
          title: '',
          question_type:'',
          is_mandatory: false
          
        },
      ],
      options:{},
      form: {
        organization_id: 'hb53361b-06bf-495b-bddf-626fc3216310',
        title: "",
        description: "",
        job_position: "TRAINER",
        active: false,
      
        job_circular_details : {
          type: "FULL_TIME",
          salary_type: "MONTHLY",
          salary_range_min: "",
          salary_range_max: "",
          workplace: "OFFLINE",
          restrictions: "",
          deadline: this.customFormatter(new Date()),
        }
      }
    };
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar"
    }),
    ...mapActions({
      actGetQuestionTypes: "question/actGetQuestionTypes",
      actSaveJob: "job/actSaveJob",
      actGetJobList: 'job/actGetJobList',
      actSaveJobQuestion: 'job/actSaveJobQuestion',
    }),
    getIsMandatory(value, index){
      console.log(value);
      this.questions[index].is_mandatory = !this.questions[index].is_mandatory;
    },
    onClickChild (value, index) {

      if(value.remove){
          delete this.options[value.remove.key];
      }
      if(value.options){
        this.options[value.options.key] = value.options;
      }
    
      this.questions[index].title = value.title;
      this.questions[index].job_question_details = this.options;
      
    }, 
    setQuestions(){
      
    }, 
    clearAll(){
      this.form = {
  
          organization_id: 'hb53361b-06bf-495b-bddf-626fc3216310',
          title: "",
          description: "",
          job_position: "TRAINER",
          active: false,
        
          job_circular_details : {
            type: "FULL_TIME",
            salary_type: "MONTHLY",
            salary_range_min: "",
            salary_range_max: "",
            workplace: "OFFLINE",
            restrictions: "",
            deadline: this.customFormatter(new Date()),
          }
      }
    },
    getQuestionType(value,index){

      this.showCheckList[parseInt(index)] = false;
      this.showShortQuestion[parseInt(index)] = false;
      this.showLongQuestion[parseInt(index)] = false;

      if(value=='CHECK_LIST' || value=='RADIO_BUTTON'){
        
        this.showCheckList[parseInt(index)] = !this.showCheckList[parseInt(index)];
      }
      if(value=='SHORT_ANSWER'){
        
        this.showShortQuestion[parseInt(index)] = !this.showShortQuestion[parseInt(index)];
      }
      if(value=='LONG_ANSWER'){
        this.showLongQuestion[parseInt(index)] = !this.showLongQuestion[parseInt(index)];
      }
    },    
    onSubmit() {
      
      this.$v.$touch();
      if(this.$v.$invalid) {
        return;
      }
      this.form.job_circular_details.deadline = this.customFormatter(this.form.job_circular_details.deadline);
      
      this.actSaveJob(this.form)
      .then(({ message, job_circular_id }) => {
        
        for (let i = 0; i < this.questions.length; i++) {
            let questionDetails = this.questions[i];
            if(questionDetails.title){
              if(!questionDetails.job_question_details) questionDetails.job_question_details = {};
              let question = {job_circular_id: job_circular_id ,...questionDetails};
            
              this.actSaveJobQuestion(question)
              .then(({ message }) => {
                  console.log(message);
                });
              }
        }
        console.log(job_circular_id);
        this.setShowSnackbar(message);
        this.dialog(false);
        this.actGetJobList();
      })

      // this.setShowSnackbar('Job saved successfully !');
      setTimeout(() => {
       this.dialog(false);
      }, 1000);
    },
    customFormatter(date) {
      return moment(date).format('YYYY-MM-D');
    },
  },
  validations: {
    form: {

      title: {required},
      description: {required},
      job_circular_details: {
        type: {required},
        salary_type: {required},
        salary_range_min: {required},
        salary_range_max: {required},
        workplace: {required},
        restrictions: {required},
        deadline: {required},
      }, 
    },
    questions: {
      
      $each: {
        title: {
          
        },
        question_type: {
          
        },
        is_mandatory: {
           
        },
        checkList : {
           
            $each: {
              options: {
                
              },
            }
        }
      },
      

    }
  }
};
</script>

<style scoped>
    .vdp-datepicker__clear-button{
      position: absolute;
      margin-left: -15px;
      margin-top: 15px;
      padding: -2px;
      font-weight: bold;
      color: red;
    }
    .date-picker-icon {
        top: 15px!important;
        right: 15px!important
    }
    .justify-content-center{
      justify-content: center;
    }
    
  
</style>

