var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"p-6",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('DialogTitle',{staticStyle:{"margin-left":"-10px","margin-right":"-10px"},attrs:{"label":"Create New Prompt","align":"center"}}),_c('div',{staticClass:"my-3"},[_c('label',{staticClass:"text-uppercase font-bold"},[_vm._v("Choose Module")]),_c('RadioButtonComponent',{attrs:{"active-class":"font-semibold","label-class":"text-capitalize","items":[
        { id: 'PTE_PRACTICE', label: 'PTE Practice' },
        { id: 'PRACTICE_TEST', label: 'Practice Test' },
        { id: 'MOCK_TEST', label: 'Mock Test' },
        { id: 'QUIZ_TEST', label: 'Quiz Test' },
      ]},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.type"}})],1),_c('div',{staticClass:"my-3"},[_c('label',{staticClass:"text-uppercase font-bold"},[_vm._v("Choose Section")]),_c('RadioButtonComponent',{attrs:{"active-class":"font-semibold","label-class":"text-capitalize","items":[
        { id: 'reading', label: 'Reading' },
        { id: 'writing', label: 'Writing' },
        { id: 'speaking', label: 'Speaking' },
        { id: 'listening', label: 'Listening' },
      ]},model:{value:(_vm.section),callback:function ($$v) {_vm.section=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"section"}})],1),_c('div',{staticClass:"my-3"},[_c('SelectComponent',{staticClass:"w-74",attrs:{"label":"Choose Item","items":_vm.items},model:{value:(_vm.form.question_type_id),callback:function ($$v) {_vm.$set(_vm.form, "question_type_id", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.question_type_id"}})],1),_c('div',{staticClass:"my-3"},[_c('label',{staticClass:"text-uppercase inline-block font-bold mb-3"},[_vm._v("Prompt")]),_c('TextEditor',{attrs:{"label":false},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.title"}})],1),_c('div',{staticClass:"my-3 flex justify-between"},[_c('md-checkbox',{model:{value:(_vm.form.active),callback:function ($$v) {_vm.$set(_vm.form, "active", $$v)},expression:"form.active"}},[_vm._v("Mark as active")]),_c('div',[_c('md-button',{staticClass:"bg-default text-uppercase rounded",on:{"click":function($event){return _vm.dialog(false)}}},[_vm._v("Cancel")]),_c('md-button',{staticClass:"bg-victoria text-white text-uppercase rounded",attrs:{"type":"submit"}},[_vm._v("Save")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }