var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-layout md-gutter my-3"},[_c('div',{staticClass:"md-layout-item md-size-75"},[_c('div',[_c('label',{staticClass:"text-uppercase font-bold"},[_vm._v("Choose Mock Test")]),_c('RadioButtonComponent',{attrs:{"active-class":"font-semibold","label-class":"text-capitalize","items":[
          { id: 1, label: 'Full Mock' },
          { id: 2, label: 'Mini Mock' },
          { id: 3, label: 'Partial Mock' },
          { id: 4, label: 'Diagonistic Mock' },
        ],"message":!_vm.$v.form.section.required && _vm.$v.form.section.$dirty
            ? 'Field is required'
            : null},model:{value:(_vm.$v.form.section.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.section, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.section.$model"}})],1)]),_c('div',{staticClass:"md-layout-item md-size-25"},[_c('SelectComponent',{staticClass:"w-full",attrs:{"label":"Choose Qestion Type","items":_vm.questionTypes,"message":!_vm.$v.form.type.required && _vm.$v.form.type.$dirty
          ? 'Field is required'
          : null},model:{value:(_vm.$v.form.type.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.type, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.type.$model"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }