<template>
  <div class="md-layout md-gutter my-3">
    <div class="md-layout-item md-size-75">
      <div>
        <label class="text-uppercase font-bold">Choose Mock Test</label>
        <RadioButtonComponent
          active-class="font-semibold"
          label-class="text-capitalize"
          :items="[
            { id: 1, label: 'Full Mock' },
            { id: 2, label: 'Mini Mock' },
            { id: 3, label: 'Partial Mock' },
            { id: 4, label: 'Diagonistic Mock' },
          ]"
          v-model.trim="$v.form.section.$model"
          :message="
            !$v.form.section.required && $v.form.section.$dirty
              ? 'Field is required'
              : null
          "
        />
      </div>
    </div>
    <div class="md-layout-item md-size-25">
        <SelectComponent
        label="Choose Qestion Type"
        class="w-full"
        v-model.trim="$v.form.type.$model"
        :items="questionTypes"
        :message="
          !$v.form.type.required && $v.form.type.$dirty
            ? 'Field is required'
            : null
        "
      />
    </div>
  </div>
</template>

<script>
import { RadioButtonComponent, SelectComponent } from "@/components";
import { required } from "vuelidate/lib/validators";
import cms from "@/data/cms"
export default {
  components: {
    RadioButtonComponent,
    SelectComponent
  },
  data() {
    return {
      questionTypes : cms.questionTypes,
      form: {
        section: "",
        type: ""
      },
    };
  },
  validations: {
    form: {
      section: { required },
      type: { required },
    },
  },
};
</script>

<style></style>
