<template>
  <div class="p-6">
    <div>
      <h3 class="text-uppercase text-lg">Reading</h3>
      <p class="text-uppercase  text-lg mt-3">
        Question Type:
        <span class="text-victoria font-bold">MCQ Choose Single Answer</span>
      </p>
    </div>
  <form @submit.prevent="onSubmit" class="p-6">
  <div class="md-layout-item md-layout md-size-100">
    <div class="md-layout-item md-layout md-gutter mt-8 md-size-100">
      <div class="md-layout-item">
        <div class="text-base line-height-27">Question</div>
        <div class="text-base line-height-27" v-html="question.title"></div>
      </div>
      <div class="md-layout-item">
        <MCQAnswer
            :items=question.question_options
            label="Choose an Answer"
            v-model.trim=form.answer_options
        />
      </div>
    </div>
    <div class="flex justify-end align-center md-layout-item md-size-50 mb-3">
      <md-button type="submit" class="bg-victoria text-white">Submit</md-button>
    </div>
  </div>
  </form>
  </div>
</template>

<script>
import MCQAnswer from "@/components/molecule/question/MultipleChoiseAnswer";
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
  components: {
    MCQAnswer,
  },
  data() {
    return {
      path: 'questions',
      form: {
        user_id: '',
        exam_enroll_id:'',
        question_test_id:'',
        question_id:'',
        test_id:'',
        active: true,
        answer_sheet_details: {
          wrong_answer_count: null,
          correct_answer_count: null
        },
        answer_options: [
          {question_options_id:'', title: '', value: ''},
          {question_options_id:'', title: '', value: ''},
          {question_options_id:'', title: '', value: ''},
          {question_options_id:'', title: '', value: ''}
        ]
      }
    }
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar"
    }),
    ...mapActions({
      actAnswerSubmit: "test/actAnswerSubmit",
      actTestList: "test/actTestList",
    }),
    onSubmit() {
      this.form.user_id = localStorage.getItem('user_id');
      this.form.question_id = this.question.id;
      this.form.test_id = this.test_id;
      console.log("Testing ID =>"+ this.form.test_id);
      console.log("Question Test ID =>"+this.form.question_test_id);
      console.log("User ID =>"+this.form.user_id);
      console.log("Question ID =>"+this.form.question_id);

      this.actAnswerSubmit(this.form)
          .then(({ message, answer_sheet_id }) => {
            console.log(answer_sheet_id);
            this.setShowSnackbar(message);
            this.dialog(false);
            this.actTestList(``);
          })
      this.setShowSnackbar('Answer submitted successfully!');
      setTimeout(() => {
        this.dialog(false);
      }, 1000);
    }
  },
  props: ['test_id', 'question_test_id'],
  mounted() {
    this.form.current = this.test_id;
    this.form.question_test_id = this.question_test_id;
  },
  computed: {
    ...mapGetters({
      question: 'test/getQuestion',
    })
  },
}
</script>