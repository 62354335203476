<template>
  <div class="p-6">
    <div class="flex justify-between align-center">
      <h2 class="text-uppercase text-lg">Assign to create question</h2>
      <md-button dense class="md-icon-button" @click="dialog(false)">
        <md-icon class="m-0 text-xl">close</md-icon>
      </md-button>
    </div>
    <div class="my-3">
      <label class="text-uppercase font-bold">Choose Creaet Process</label>
      <RadioButtonComponent
        active-class="font-semibold"
        label-class="text-capitalize"
        :items="[
          { id: 1, label: 'Assign to branch' },
          { id: 2, label: 'Assign to trainee' },
          { id: 3, label: 'Assign to Admin' },
        ]"
      />
    </div>
    <div class="my-3">
      <SelectComponent
        label="Select Trainer"
        class="w-74"
        v-model.trim="$v.form.trainer_id.$model"
        :message=" !$v.form.trainer_id.required && $v.form.trainer_id.$dirty ? 'Field is required' : null"
      />
    </div>
    <div class="my-3">
      <label class="text-uppercase inline-block font-bold mb-3"
        >Description Of Task</label
      >
      <TextEditor
        v-model.trim="$v.form.description.$model"
        :message=" !$v.form.description.required && $v.form.description.$dirty ? 'Field is required' : null"
        :label="false"
      />
    </div>
    <div class="md-layout md-gutter my-3">
      <div class="md-layout-item md-size-40">
        <SelectComponent
            :items="questionTypes"
            v-model.trim="$v.form.question_id.$model"
            :message=" !$v.form.question_id.required && $v.form.question_id.$dirty ? 'Field is required' : null"
            label="Choose Question Type"
            class="w-full"
        />
      </div>
    </div>
    <div class="md-layout md-gutter my-3">
      <div class="md-layout-item md-size-40">
        <Datepicker
            label="Deadline"
            v-model.trim="$v.form.deadline.$model"
            :message=" !$v.form.deadline.required && $v.form.deadline.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-40">
        <SelectComponent
          :items="[{id:1, name: '1'}, {id: 2, name: '2'}]"
          label="Quantity"
          class="w-74"
          v-model.trim="$v.form.quantity.$model"
          :message=" !$v.form.quantity.required && $v.form.quantity.$dirty ? 'Field is required' : null"
        />
      </div>
    </div>
    <div class="md-layout md-gutter my-3">
      <div class="md-layout-item md-size-40">
        <SelectComponent
            v-model.trim="$v.form.user_id.$model"
            :message=" !$v.form.user_id.required && $v.form.user_id.$dirty ? 'Field is required' : null"
            label="Checked By"
            class="w-full"
        />
      </div>
    </div>
    <div class="flex justify-end">
      <md-button class="bg-victoria text-white rounded" @click="onSubmit">Submit</md-button>
    </div>
  </div>
</template>

<script>
import {
  Datepicker,
  RadioButtonComponent,
  SelectComponent,
} from "@/components";
import TextEditor from "@/components/atom/form/TextEditor";
import { required } from "vuelidate/lib/validators";
import cms from "@/data/cms";
import { mapMutations } from "vuex";
export default {
  components: {
    TextEditor,
    Datepicker,
    SelectComponent,
    RadioButtonComponent,
  },
  data() {
    return {
      questionTypes: cms.questionTypes,
      form: {
        trainer_id: "",
        description: "",
        question_id: "",
        deadline: "",
        quantity: "",
        user_id: ""
      },
    };
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar",
    }),
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.setShowSnackbar("Assign to saved successfully !");
      setTimeout(() => {
        this.dialog(false);
      }, 1000);
    },
  },
  validations: {
    form: {
      trainer_id: { required },
      description: { required },
      question_id: { required },
      deadline: { required },
      quantity: { required },
      user_id: { required },
    },
  },
};
</script>
